.organigrama-listing__wrapper {
    width: 100%;

    & .organigrama-listing__content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 2rem;
        width: 100%;
    }

    & ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    & h2 {
        margin: 0;
    }
    
    & li {
        display: flex;
        border-radius: 5px;
        background-color: #f5f5f5;
        border: 1px solid #e0e0e0;
        overflow: hidden;
    }

    & .organigrama-listing__img {
        width: 200px;
        height: 200px;
        overflow: hidden;

        & img {
            min-height: 100%;
            min-width: 100%;
            object-fit: cover;
        }
    }

    & svg {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }

    & a {
        color: var(--color-primary) !important;
        cursor: pointer;
    }
}

.normativa-listing__wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    & .normativa-listing__content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    & span {
        font-size: 1.1rem;
    }

    & ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        padding: 0;
        margin: 0;
        border-radius: 5px;
        background-color: #f5f5f5;
        border: 1px solid #e0e0e0;
        overflow: hidden;
    }

    & li {
        padding: 1rem;
        border-bottom: 1px solid #e0e0e0;

        &:last-child {
            border-bottom: none;
        }

        &:nth-child(odd) {
            background-color: #e0e0e0;
        }
    }

    & svg {
        width: 2rem;
        height: 2rem;
        margin-right: 0;
        opacity: 0.8;
        transition: 0.3s all;

        &:hover {
            opacity: 1;
        }
    }

    & h2 {
        font-size: 1.25rem !important;
        color: var(--color-primary);
        margin-bottom: 0;
        vertical-align: center;
        display: flex;
        align-items: center;
    }

    & a {
        color: var(--color-primary) !important;
        cursor: pointer;
        border-radius: 5px;
        transition: 0.3s all;
        width: fit-content;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
            color: var(--color-primary-dark) !important;
        }

        &:has(svg) {
            display: flex;
            background: none !important;
            min-width: 2rem;
            justify-content: center;

            &:hover {
                background: none !important;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .organigrama-listing__wrapper {
        & ul {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    
}