.cartel-builder__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    aspect-ratio: 210 / 297;
    overflow: hidden;
    padding: 7%;
}

.cartel-builder__wrapper.closed {
    opacity: 0.25;
    cursor: default !important;
}

.cartel-builder__wrapper.finished {
    opacity: 0.25;
}

.cartel-builder__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    margin-top: 1rem;
}

.cartel-builder__background,
.cartel-builder__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cartel-builder__background {
    display: flex;
    align-items: center;
    z-index: -2;
    opacity: 0.75;
    object-fit: cover;
    filter: saturate(0);
}

.cartel-builder__background img {
    object-fit: cover;
    min-height: 100%;
    min-width: 100%;
}

.cartel-builder__backdrop {
    mix-blend-mode: multiply;
    z-index: -1;
}

.cartel-builder__wrapper.closed .cartel-builder__backdrop,
.cartel-builder__wrapper.finished .cartel-builder__backdrop {
    filter: saturate(0) brightness(0.5);
    opacity: 1;
}

.cartel-builder__logo {
    height: 10%;
    display: flex;
}

.cartel-builder__availability {
    font-weight: bold;
    font-size: 0.75rem;
    z-index: 10;
    height: fit-content;
    width: fit-content;
    padding: 0.25rem 0.5rem;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
}

.cartel-builder__wrapper.closed .cartel-builder__availability{
    background-color: white !important;
    color: black;
}

.cartel-builder__wrapper.finished .cartel-builder__availability{
    background-color: white !important;
    color: black;
}


.cartel-builder__wrapper:not(.closed, .finished) .cartel-builder__availability {
    display: none;
}

.cartel-builder__logo img {
    height: 100%;
    filter: saturate(0) invert(1) brightness(10);
}

.cartel-builder__top {
    display: flex;
    flex: 0 0 auto;
}

.cartel-builder__top-left {
    flex: 1 0 10%;
    margin-right: 1rem;
    border-top: 0.2em solid white;
    border-left: 0.2em solid white;
}

.cartel-builder__top-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5%;
    flex: 0 1 auto;
    padding-bottom: 1em;
}

.cartel-builder__title {
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    text-align: right;
    color: white;
    line-height: 100%;
    flex: 1 1 0;
    margin-top: -0.2em;
}

.cartel-builder__type {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    text-align: right;
    color: white;
    opacity: 0.8;
    font-size: 0.5rem;
}

.cartel-builder__ects {
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    text-align: right;
    color: white;
    opacity: 0.8;
    margin-bottom: 0.5rem;
}

.cartel-builder__bottom {
    flex: 1 0 0;
    display: flex;
    align-items: flex-end;
    border-right: 0.2em solid white;
    border-bottom: 0.2em solid white;
    border-left: 0.2em solid white;
}

@media only screen and (max-width: 991px) {

    .cartel-builder__wrapper {
        aspect-ratio: 16 / 9;
    }

    .cartel-builder__title {
        font-size: 1.25rem !important;
    }

    .cartel-builder__type {
        font-size: 1rem !important;
    }
}