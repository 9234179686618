.layout-preset {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & h2, p {
        margin: 0 !important;
    }

    & img {
        max-width: none !important;
    }
}

.header__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;

    & img {
        max-width: 80px !important;
        height: auto !important;
        object-fit: contain;
    }
}

.layout-preset__wrapper:not(:last-child) {
    margin-bottom: 1rem;
}

.header__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    & h2, h3 {
        width: 100%;
    }

    & h3 {
        font-style: italic;
    }
}

.content__wrapper--row {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.content__wrapper--column {
    column-count: 2;
    gap: 2rem;

    & > div:not(:last-of-type) {
        margin-bottom: 1rem !important;
    }
}

.content__wrapper--column,
.content__wrapper--row {
    
    & a {
        text-decoration: none;
        color: var(--color-primary);
        font-weight: 700;
    }
}

@media screen and (max-width: 991px) {
    .content__wrapper--column {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        column-count: 1;
    }
}