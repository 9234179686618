@font-face {
	font-family: "Montserrat";
	src: url("../fonts/Montserrat-Light.woff2") format("woff2"),
		url("../fonts/Montserrat-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Montserrat";
	src: url("../fonts/Montserrat-Bold.woff2") format("woff2"),
		url("../fonts/Montserrat-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Montserrat";
	src: url("../fonts/Montserrat-Regular.woff2") format("woff2"),
		url("../fonts/Montserrat-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

:root {
	--color-primary: #006d38;
	--color-primary-dark: #005f32;
	--color-primary-light: #06c270;
}

/* Scrollbar */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #ccc;
	border-radius: 10px;
}

.bannerslider {
	background: url("../img/banner.jpg") no-repeat right center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.bannerslider {
	width: 100%;
	height: 300px;
}

.formabot2 {
	margin-right: 45px !important;
}

/*cookies*/

.intsection.cookiespage .col-12.fixpadding {
	padding: 5px 0 25px 0 !important;
}

.intsection.cookiespage {
	text-align: left;
	margin-top: 150px !important;
	font-size: 18px;
	display: flex;
	align-items: flex-start;
	font-weight: 400;
}

.intsection.cookiespage .col-12 {
	padding: 15px 0;
	min-height: auto;
}

.intsection.cookiespage b {
	font-weight: 500;
	text-decoration: underline;
}

.cookiespage h2 {
	font-size: 24px !important;
	font-weight: bold !important;
	margin: 0;
}

.intsection.cookiespage .col-12 h4 {
	font-size: 24px !important;
	font-weight: 600 !important;
	margin: 0;
}

.intsection.cookiespage p {
	white-space: pre-line;
	vertical-align: bottom;
	margin: 5px 0;
}

.oculto {
	visibility: hidden;
}

.contpanelcookies {
	padding: 20px;
}

.conborde {
	border: 1.5px solid #333;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.cerrarcookies {
	display: flex;
	justify-content: flex-end;
	margin: 0 !important;
}

.cerrarcookies img {
	width: 40px;
}

.contpanelcookies p {
	margin: 0 15px !important;
	line-height: 30px;
	font-size: 18px;
}

.cerrarcookies button {
	border: none;
	margin: 0 !important;
	top: -70px;
	right: 5px;
	width: 40px;
	height: 40px;
	position: relative;
	padding: 0;
	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	-ms-transition: all 0.25s ease;
	-o-transition: all 0.25s ease;
	transition: all 0.25s ease;
}

.adv-search__calendar--container {
	display: flex;
	flex-direction: row;
	align-items: center;

	background: var(--color-light);
	width: fit-content;
	border-radius: var(--border-radius);

	& button {
		background: transparent;
		border: none;
		gap: 1rem;
		padding: 0.5rem 1.5rem;
		color: var(--color-dark);
	}

	& span {
		font-family: "Ubuntu", sans-serif;
		flex: 1 1 0;
		display: flex;
		color: var(--color-dark);
	}

	&:hover svg * {
		fill: var(--color-dark) !important;
	}
}

.start-date,
	textarea {
		font-family: "Ubuntu", sans-serif;
		height: 40px;
		width: 100%;
		background-color: transparent;
		border-radius: var(--border-radius);
		border: var(--border-width) solid var(--color-medium);
		color: var(--color-dark);
		padding: 0 1rem;
		flex-grow: 1;

		&:hover svg * {
			fill: var(--color-dark) !important;
		}
	}

.Calendar {
	position: absolute !important;
}

.calendar-hide {
	display: none;
}

.calendar-show {
	display: block;
}

.contpanelcookies h3 {
	font-size: 24px;
	font-weight: bold;
}

.contpanelcookies .col-12 {
	margin: 15px 0;
}

.cookiesacept {
	display: flex;
	align-items: center;
	justify-content: center;
}

.centermid {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-width: 350px;
}

.cookiesacept .col-6:first-child {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.cookiesacept .col-6:last-child {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.cookiesacept span {
	margin: 0 15px;
}

.cookiesacept button {
	margin: 0 !important;
	font-size: 16px !important;
}

.contseparador {
	padding: 0 15%;
}

.contpanelcookies span {
	font-size: 18px;
}

.cookiesacept button {
	background: #000;
	color: #fff;
	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	-ms-transition: all 0.25s ease;
	-o-transition: all 0.25s ease;
	transition: all 0.25s ease;
}

.cookiesacept button:focus-visible {
	background: #fff;
	color: #000;
}

.panelcookies hr {
	border-top: #000 solid !important;
}

.cookiesacept img {
	width: 24px;
}

.cookiesacept button {
	margin-right: 10px !important;
	border: 1px solid #000;
}

button.aceptar:focus-visible {
	background: #205383 !important;
	color: #fff !important;
}

button.noaceptar:focus-visible {
	background: #205383 !important;
	color: #fff !important;
}

.cookiesacept.col-12 {
	padding: 0 !important;
}

button.aceptar {
	border: none;
	margin-right: 0 !important;
	background: #006137 !important;
}

button.noaceptar {
	border: none;
	margin-right: 0 !important;
	background: #822633 !important;
}

button.noaceptar:hover {
	background: #205383 !important;
	color: #fff !important;
	pointer-events: inherit;
}

.fixmg {
	margin: 0 !important;
}

.fixmg button {
	right: -71px;
	position: relative;
	top: -17px;
	max-width: 141px;
}

.fixmg2 {
	margin-top: 15px;
}

.fixmg2 button.aceptar {
	margin: 0 !important;
}

.panelcookies {
	background: #090909ad;
	width: 100% !important;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex-direction: column;
	text-align: center;
	position: fixed;
	z-index: 99999999;
}

.contpanelcookies {
	background: #ffffffde;
	width: 40% !important;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	-webkit-border-radius: 0;
	border-radius: 0;
}

.cookiesacept button:hover {
	background-color: #fff !important;
	color: #000 !important;
	border: 1px solid #000;
}

.cerrarcookies button:hover {
	opacity: 0.5;
}

.cookies {
	position: fixed;
	display: flex;
	justify-content: center;
	color: #fff;
	align-items: center;
	font-size: 16px;
	z-index: 999999;
	bottom: 0;
	background: #000;
	min-height: 70px;
}

.cookies span {
	font-size: 16px;
	padding: 0 10px;
}

.cookies button {
	font-size: 16px !important;
}

.cookies button {
	color: #fff;
	text-decoration: none;
	padding: 8px 12px;
	font-size: 16px;
	background: #006d38;
	margin: 0 10px !important;
	border: 0;
	font-weight: 600 !important;
}

.cookies button:last-child {
	background: #822633 !important;
	white-space: nowrap;
}

.cookies button:hover {
	background-color: #fff !important;
	color: #000 !important;
}

.cookies button {
	-webkit-transition: all 0.25s ease;
	-moz-transition: all 0.25s ease;
	-ms-transition: all 0.25s ease;
	-o-transition: all 0.25s ease;
	transition: all 0.25s ease;
}

button.aceptar {
	border: 1px solid #008915 !important;
	margin: 0 15px 0 0 !important;
}

button.noaceptar {
	border: 1px solid #dc2a43 !important;
}

button.aceptar:hover {
	background: #008915 !important;
	color: #fff !important;
	border: 1px solid #008915 !important;
}

button.noaceptar:hover {
	background: #dc2a43 !important;
	color: #fff !important;
	border: 1px solid #dc2a43 !important;
}

.accept2 {
	margin-left: 15px !important;
}

.cookies button.configurar {
	background: #fff !important;
	color: #000 !important;
	margin: 0 !important;
}

.cookies button.configurar:hover {
	background: #205383 !important;
	color: #fff !important;
}

.cookies button.configurar:focus-visible {
	background: #205383 !important;
	color: #fff !important;
}

.cookies button.aceptar {
	margin: 0 !important;
}

.cerrarcookies {
	display: none !important;
	position: relative;
}

/*********/

.sep15top {
	margin-top: 15px;
}

.intsection.accesibilidad h2 {
	font-size: 24px;
}

.intsection.accesibilidad .col-12 {
	display: block;
	padding: 15px 0;
	min-height: auto;
}

.intsection.accesibilidad {
	align-items: flex-start;
}

.empresas > h2 {
	margin-bottom: 5px;
}

.empresas h2,
.empresas h3 {
	width: 100%;
	font-size: 24px;
}

.empresas > h2 {
	margin-bottom: 15px;
}

.ptgas > h2 {
	margin-bottom: 5px;
}

.ptgas h2,
.ptgas h3 {
	width: 100%;
	font-size: 24px;
}

.pdi > h2 {
	margin-bottom: 5px;
}

.pdi h2,
.pdi h3 {
	width: 100%;
	font-size: 24px;
}

.estudiantes > h2 {
	margin-bottom: 15px;
}

.estudiantes h2,
.estudiantes h3 {
	width: 100%;
	font-size: 24px;
	text-align: left;
}

h2.oculto {
	color: #fff;
	font-size: 19px;
	font-weight: 600;
	opacity: 0;
	margin-top: -25px;
}

h1 {
	font-size: 2rem !important;
	font-weight: 700 !important;
	color: #fff !important;
}

h2 {
	font-size: 1.5rem !important;
	font-weight: 600 !important;
}

h3 {
	font-size: 1.25rem !important;
}

p {
	font-size: 1.2rem !important;
	color: #000 !important;
	text-align: justify;
    text-justify: inter-word;
}

.contnovedades h2 {
	color: #fff !important;
}

select:focus {
	outline: 1px !important;
	border: 2px solid #ccc;
}

select:focus-visible {
	outline: 1px !important;
	border: 2px solid #ccc;
}

select option:focus {
	outline: 1px !important;
	border: 2px solid #ccc;
}

select option:focus-visible {
	outline: 2px solid #000 !important;
	-webkit-border-radius: 3px 3px 3px 3px;
	border-radius: 3px 3px 3px 3px;
}

button:focus-visible {
	outline: 2px solid #000 !important;
	-webkit-border-radius: 3px 3px 3px 3px;
	border-radius: 3px 3px 3px 3px;
}

button.accordion-button:focus-visible {
	outline: 2px solid #000 !important;
	-webkit-border-radius: 3px 3px 3px 3px;
	border-radius: 3px 3px 3px 3px;
}

select:focus-visible {
	outline: 1px solid #000 !important;
}

.logo:focus-visible {
	border: none;
	outline: 2px solid #000 !important;
	-webkit-border-radius: 3px 3px 3px 3px;
	border-radius: 3px 3px 3px 3px;
}

.novedad button:focus-visible {
	outline: 2px solid #000 !important;
	-webkit-border-radius: 3px 3px 3px 3px;
	border-radius: 3px 3px 3px 3px;
}

.volver {
	max-width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
}

.contvolver {
	width: 93%;
	max-width: 1600px;
	display: flex;
	justify-content: flex-start;
	margin-bottom: 15px;
}

.volver a {
	color: #000;
	text-decoration: none;
}

.volver a:focus {
	text-decoration: underline;
}

.flecha {
	display: flex;
}

.flechawhite {
	display: none;
}

.flechawhite {
	width: 10px;
	margin: 10px 0 0 5px;
}

.flecha {
	width: 10px;
	margin: 14px 0 0 5px;
}

.breadcrumb button {
	font-family: "Montserrat" !important;
}

.breadcrumb {
	font-family: "Montserrat" !important;
	margin: 0 !important;
}

.titulo {
	margin-bottom: 15px;
}

.container-fluid {
	background: #fff;
}

.uja-footer__logo-uja img {
	width: 120px;
	margin-bottom: 15px;
}

.listarrss {
	margin: 0 !important;
	margin-top: 15px;
}

.listarrss img {
	width: 24px;
}

.anima button:hover {
	background: #006137;
	color: #fff;
	border: 2px solid #006137;
}

.contnovedades button:hover {
	background: #000;
	color: #fff;
	border: 1px solid #000;
}

.ampliado .accordion-body button:hover {
	background: #3c726b !important;
	color: #fff !important;
	border: 2px solid #3c726b !important;
}

.anima .col-6:first-child {
	opacity: 0;
	-moz-transform: translateX(-150px) translateY(0px);
	-webkit-transform: translateX(-150px) translateY(0px);
	-o-transform: translateX(-150px) translateY(0px);
	-ms-transform: translateX(-150px) translateY(0px);
	transform: translateX(-150px) translateY(0px);
}

.anima .col-6:last-child {
	opacity: 0;
	-moz-transform: translateX(150px) translateY(0px);
	-webkit-transform: translateX(150px) translateY(0px);
	-o-transform: translateX(150px) translateY(0px);
	-ms-transform: translateX(150px) translateY(0px);
	transform: translateX(150px) translateY(0px);
}

.anima .col-6.visible:first-child {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	-moz-transform: translateX(0) translateY(0px);
	-webkit-transform: translateX(0) translateY(0px);
	-o-transform: translateX(0) translateY(0px);
	-ms-transform: translateX(0) translateY(0px);
	transform: translateX(0) translateY(0px);
	opacity: 1;
}

.anima .col-6.visible:last-child {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	-moz-transform: translateX(0) translateY(0px);
	-webkit-transform: translateX(0) translateY(0px);
	-o-transform: translateX(0) translateY(0px);
	-ms-transform: translateX(0) translateY(0px);
	transform: translateX(0) translateY(0px);
	opacity: 1;
}

/* .cartelesverano .anima .cursoverano {
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    -moz-transform: translateX(-100px) translateY(0);
    -webkit-transform: translateX(-100px) translateY(0);
    -o-transform: translateX(-100px) translateY(0);
    -ms-transform: translateX(-100px) translateY(0);
    transform: translateX(-100px) translateY(0);
    opacity: 0;
} */

.cartelesverano .anima .cursoverano.visible {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	-moz-transform: translateX(0) translateY(0px);
	-webkit-transform: translateX(0) translateY(0px);
	-o-transform: translateX(0) translateY(0px);
	-ms-transform: translateX(0) translateY(0px);
	transform: translateX(0) translateY(0px);
	opacity: 1;
}

.cartelesverano .anima .cursoverano {
	-moz-transform: translateX(-100%);
	-webkit-transform: translateX(-100%);
	-o-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	transform: translateX(-100%);
	opacity: 0;
}

@keyframes slide-right {
	to {
		-moz-transform: translateX(0);
		-webkit-transform: translateX(0);
		-o-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

.cartelesverano .anima .cursoverano.visible1 {
	animation: 0.2s ease slide-right 0s forwards;
}

.cartelesverano .anima .cursoverano.visible2 {
	animation: 0.2s ease slide-right 0.1s forwards;
}

.cartelesverano .anima .cursoverano.visible3 {
	animation: 0.2s ease slide-right 0.2s forwards;
}

.cartelesverano .anima .cursoverano.visible4 {
	animation: 0.2s ease slide-right 0.3s forwards;
}

.cartelesverano .anima .cursoverano.visible5 {
	animation: 0.2s ease slide-right 0.4s forwards;
}

.cartelesverano .anima .cursoverano.visible6 {
	animation: 0.2s ease slide-right 0.5s forwards;
}

.cartelesverano .anima .cursoverano.visible7 {
	animation: 0.2s ease slide-right 0.6s forwards;
}

.cartelesverano .anima .cursoverano.visible8 {
	animation: 0.2s ease slide-right 0.7s forwards;
}

.cartelesverano .anima .cursoverano.visible9 {
	animation: 0.2s ease slide-right 0.8s forwards;
}

#root {
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

html {
	font-family: "Montserrat";
	font-size: 13px !important;

	&:has(.inicio) {
		font-size: 18px !important;
	}
}

header .container-fluid {
	padding: 0;
}

header .navbar {
	padding: 0;
}

header {
	max-width: 1800px;
	margin: 0 auto;
}

.flex-column {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.navbar-expand-lg .navbar-collapse {
	justify-content: flex-end;
}

.navbar.bg-light {
	background: #fff !important;
}

.nav-link {
	font-size: 18px !important;
	margin: 0;
	font-family: "Montserrat";
	color: #101010;

	&.active {
		color: #38ca8a !important;
	}
}

select {
	border: 1px solid #000;
	min-height: 30px;
}

select option {
	border-radius: 0 0 0 0 !important;
	-webkit-border-radius: 0 0 0 0 !important;
}

.nav-link {
	padding: 0 !important;
	margin: 0 !important;
}

.navbar-toggler {
	width: auto;
	border: 0 !important;
}

.nav-item button {
	padding: 0;
	background: none;
	font-size: 14px !important;
	width: auto;
	margin-top: 0 !important;
	border: 0;
	font-family: "Montserrat";
}

.nav-item .active button {
	color: #38ca8a !important;
}

.nav-item.formabot > a.active button {
	color: #101010 !important;
}

.nav-item a button:hover {
	color: #ccc !important;
}

.migapan {
	max-width: 1800px;
	width: 100%;
	padding: 0 5%;
	display: flex;
	margin: 0 auto;
	align-items: flex-start !important;
}

.migapan button {
	padding: 0;
	background: none;
	font-size: 18px !important;
	width: auto;
	margin-top: 0 !important;
	border: 0;
	color: #00a65d !important;
	line-height: 16px;
}

.migapan a {
	color: #00a65d !important;
}

.migapan.marron button {
	color: #a58609 !important;
}

.migapan.marron a {
	color: #a58609 !important;
}

.migapan.verdenormal button {
	color: #006d38 !important;
}

.migapan.verdenormal a {
	color: #006d38 !important;
}

.migapan.rojo button {
	color: #822633 !important;
}

.migapan.rojo a {
	color: #822633 !important;
}

.migapan.gris button {
	color: #828385 !important;
}

.migapan.gris a {
	color: #828385 !important;
}

.migapan.verdeclaro button {
	color: #006d38 !important;
}

.migapan.verdeclaro a {
	color: #006d38 !important;
}

.migapan.azul button {
	color: #205383 !important;
}

.migapan.azul a {
	color: #205383 !important;
}

.cont_footer .nav-item {
	margin: 0 !important;
}

.cont_footer .nav-item button {
	color: #fff;
}

.logo img {
	max-width: 210px;
}

.cont_logo {
	max-width: 100%;
	margin: 0 auto;
	padding: 0 30px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.mapaweb ul {
	list-style: disc;
}

h1.titularmapaweb {
	color: #000 !important;
	margin: 45px 0 0 0 !important;
	padding: 0 !important;
	font-size: 30px;
}

.intsection.mapaweb {
	display: flex;
	align-items: flex-start;
}

footer {
	background: #006d38;
	display: flex;
	padding: 115px 5%;
}

.footer__top,
.footer__bottom {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 100%;

	& > * {
		flex: 1 1 0;
	}
}

.cont_footer {
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	padding: 2rem 5%;
	width: 1800px !important;
}

footer p {
	color: white !important;
	font-size: 1rem !important;
}

.col2footer {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

footer ul {
	display: flex;
	flex-direction: row;
	align-items: center;
	list-style: none;
	padding: 0;
	margin: 0;
	flex-wrap: wrap;
	width: 80%;
	justify-content: flex-end;
}

footer ul li a {
	font-size: 1rem;
	padding: 0.5rem;
	text-decoration: none;
	color: #fff !important;
	text-align: right;
}

.uja-footer__copyright-menu-item {
	color: #fff !important;
}

footer ul li {
	margin-bottom: 10px;
	color: white;
	font-weight: 300;
}

footer ul li:last-child {
	margin-bottom: 0;
}

.intsection {
	max-width: 1800px;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
}

.ampliado {
	align-items: center;
}

.info {
	display: flex;
	justify-content: center;
	max-width: 1800px;

	& h1 {
		font-size: 36px !important;
	}

	& p {
		font-size: 21.6px !important;
	}
}

.intsection p {
	color: black;
}

.item p {
	font-size: 22px !important;
	color: black !important;
}

.contnovedades h2 {
	margin-bottom: 1rem !important;
}

/*novedades*/

.contnovedades {
	max-width: 1800px;
	margin: 0 auto;
	margin-top: -4rem;
	padding: 0 5%;
}

.contnovedades .novedad {
	background: #e4e4e4;
	padding: 2%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.contnovedades .novedad.f1 {
	background: #205383;
}

.contnovedades .novedad.f2 {
	background: #a58609;
}

.contnovedades .novedad.f3 {
	background: #822633;
}

.contnovedades {
	display: flex;
	justify-content: center;
}

.novedad {
	flex: 1 1 0;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: space-between !important;
	min-height: 250px;
}

.novedad h2 {
	font-family: "Montserrat";
	font-size: 1.75rem;
	margin: 0;
	color: #fff;
	padding-top: 4rem;
}

.novedad p {
	margin-top: 1rem !important;
	font-size: 1rem !important;
	line-height: 1.5rem !important;
	margin: 0;
	color: #fff !important;
}

.novedad button {
	color: #fff;
}

.novedad button {
	border: 1px solid #fff;
	width: fit-content;
}

button {
	padding: 8px 12px;
	background: none;
	font-size: 1rem !important;
	width: auto;
	opacity: 1;
	transition: all 0.3s ease;

	&:hover {
		opacity: 0.75;
	}
}

.contnovedades .col-12 {
	max-width: 100%;
	display: flex;
}

.info .col-6 h2 {
	font-size: 36px;
	color: #fff;
	font-weight: bold;
}

.info .col-6:first-child {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	height: 80%;
	flex-direction: column;
}

.info .col-6 p {
	color: #fff;
	font-size: 1rem;
	margin: 5px 0;
}

.item .col-12 {
	max-width: 1800px;
	display: flex;
	flex-direction: row;

	& .col-6 {
		flex: 1 1 0;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		& .sliderimg {
			width: 100%;
		}
	}

	& p {
		width: 75%;
	}
}

.item {
	display: flex;
	justify-content: center;
}

.item .col-6:first-child {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.item .col-6:last-child {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	justify-content: center;
	text-align: right;
}

.contnovedades h4 {
	font-size: 28px !important;
	font-family: "Montserrat";
	font-weight: bold;
}

h4 {
	font-size: 48px !important;
	font-family: "Montserrat";
	margin-bottom: 15px;
	line-height: 52px;
	font-weight: bold !important;
}

main {
	display: flex;
	flex-direction: column;
	gap: 4rem;
	flex: 1 0 auto !important;

	& > *:last-child {
		padding-bottom: 2rem;
	}
}

.item .col-12 {
	max-width: 1800px;
	display: flex;

	& .col-6 {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
}

.item {
	display: flex;
	justify-content: center;
}

.item .horizontal-divider {
	width: 50%;
}

.item:nth-of-type(even) {
	flex-direction: row-reverse;
	background: #f5f5f5;

	& .horizontal-divider {
		background: #f2f2f2;
	}
}

.item h2 {
	font-size: 1.75rem !important;
	font-weight: 700 !important;
}

.item button {
	border: 1px solid rgb(29, 29, 29);

	&:hover {
		border: 1px solid transparent;
	}
}

.item > .col-12 {
	padding: 2rem 5%;
	flex: 1 0 auto;
	gap: 1rem;

	& .col-6 {
		flex: 1 0 auto;
	}
}

.item .col-6:first-child {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
}

.item .col-6:last-child {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	justify-content: center;
	text-align: right;
}

.item .fixwidth {
	width: 40%;
}

.ensancha {
	justify-content: space-between;
}

.iconmail {
	width: 31px;
	margin: -9px 15px 0 0px;
}

.iconphone {
	width: 31px;
	margin: -9px 15px 0 0px;
}

footer .col-4:first-child ul {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-items: flex-start;
	list-style: none;
	padding: 0;
	margin: 0;
}

footer .col-4:last-child ul {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-items: flex-end;
	list-style: none;
	padding: 0;
	margin: 0;
}

footer .col-4:nth-child(2) {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-items: center;
	list-style: none;
	padding: 0;
	margin: 0;
}

footer .col-4:last-child ul li:first-child img {
	width: 60px;
}

footer .col-4:first-child h4 {
	font-size: 24px !important;
	color: #fff;
	line-height: 28px;
}

footer .footer__menu {
	display: flex;
	justify-content: space-between !important;
	height: 100% !important;
	flex: 1 1 0;
	color: #fff;
	flex-direction: column;
	align-items: flex-end;
}

footer ul li {
	display: flex;
	font-size: 16px;
	line-height: 20px;
	align-items: center;
	margin-bottom: 0;
}

footer ul li:last-child a {
	margin: 4px 0 4px 4px;
}

footer ul {
	display: flex;
	flex-direction: row;
	align-items: center;
	list-style: none;
	padding: 0;
	margin: 0;
	flex-wrap: wrap;
}

.uja-footer__vcard {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.uja-footer__vcard,
.listarrss {
	flex: 1 1 0;
}

.footer__vcard a {
	color: #fff;
}

.uja-footer__datos-contacto-inner p {
	margin-bottom: 5px !important;
	color: #fff;
}

.uja-footer__datos-contacto-inner a {
	color: #fff;
}

.uja-footer__datos-contacto-inner {
	display: flex;
	justify-content: flex-start !important;
}

.uja-footer__logo-uja {
	margin-right: 15%;
}

/*COMUN*/

.contslider .info {
	padding: 0 5% !important;
}

.contslider p {
	color: white !important;
}

.intsection .col-12 {
	display: flex;
}

.intsection .col-12 .col-6 {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.intsection .col-12 .col-6:first-child h4 {
	font-size: 18px;
}

.intsection .col-12 .col-6:last-child {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.intsection .col-12 .col-6:first-child h4 {
	font-size: 30px !important;
}

.intsection .col-12:last-child .col-6:last-child {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.intsection .col-12:first-child .col-6:last-child {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
}

.intsection .col-12 {
	display: flex;
	flex-direction: column;
}

.intsection .col-12 h4 {
	width: 100%;
	font-size: 30px !important;
	/* font-family: 'Montserrat'; */
}

.intsection .col-12 .col-4 .col-12 {
	display: flex;
	flex-direction: column;
}

.separador {
	margin: 0 !important;
	padding: 0 !important;
	min-height: 5px !important;
}

.intsection .col-12 .col-4 .col-12 {
	padding: 25px !important;
}

.intsection p {
	white-space: pre-line;
	vertical-align: bottom;
}

.contslider {
	display: flex;
	max-height: 300px;
	max-width: 100%;
	justify-content: center;
	background-size: cover;
	position: relative;
	overflow: hidden;
}

.inicio .contslider {
	margin: 0 !important;
	background: #00a65d;
	overflow: visible;
	height: 600px;
	max-height: 600px !important;

	& .col-6:has(> .sliderimg):before {
		overflow: visible;
	}

	& .col-6.col-6:has(> .sliderimg) {
		flex-grow: 1;
		max-width: 100% !important;
	}

	& .sliderimg {
		width: 150% !important;
		transform: translateY(20px);
	}

	& h1 {
		align-self: flex-start;
	}
}

.estudiantes .contslider {
	background: #3c726b;

	& .col-6:has(> .sliderimg):before {
		background: #3c726b;
	}
}

.pdi .contslider {
	background: #a58609;

	& .col-6:has(> .sliderimg):before {
		background: #a58609;
	}
}

.empresas.innovacion .contslider {
	background: #205383;

	& .col-6:has(> .sliderimg):before {
		background: #205383;
	}
}

.empresas.tecnologias .contslider {
	background: #205383;

	& .col-6:has(> .sliderimg):before {
		background: #205383;
	}
}

.empresas.resoluciones .contslider {
	background: #822633;

	& .col-6:has(> .sliderimg):before {
		background: #822633;
	}
}

.ptgas .contslider {
	background: #828385;

	& .col-6:has(> .sliderimg):before {
		background: #828385;
	}
}

.empresas .contslider {
	background: #1f6e34;

	& .col-6:has(> .sliderimg):before {
		background: #1f6e34;
	}
}

.microcredenciales .contslider {
	background: #822633;
}

.microcredenciales .contslider {
	background: #822633;

	& .col-6:has(> .sliderimg):before {
		background: #822633;
	}
}

.intsection {
	padding: 0 5%;
	width: 100%;
}

/*error*/

.error2 h2 {
	font-size: 80px;
	font-weight: bold !important;
	text-align: center;
	padding: 0 15px;
}

.error2 img {
	width: 330px;
}

.error2 h4 {
	font-weight: normal !important;
	margin-top: 15px;
	text-align: center;
	padding: 0 15px;
}

.intsection.ampliado .col-12.centermid {
	align-items: center !important;
}

.ampliado.error2 {
	padding: 0 !important;
}

.error2 .centermid {
	align-items: center !important;
	height: auto;
	min-height: auto;
	margin-bottom: 45px !important;
}

/*ESTUDIANTES*/

.intsection h5 {
	text-align: left;
	width: 100%;
	margin-bottom: 20px;
	font-weight: 600;
	color: #101010;
	font-size: 22px;
	font-family: "Montserrat";
}

.estudiantes,
.pdi,
.ptgas,
.error,
.microcredenciales,
.empresas {
	z-index: 0;
	position: relative;
}

.estudiantes .intsection .col-12 .col-4 .col-12 {
	display: flex;
	flex-direction: column;
	padding: 0 35px 0 0 !important;
}

.estudiantes .intsection .col-12 .col-4:last-child .col-12 {
	display: flex;
	flex-direction: column;
	padding: 0 0 0 0 !important;
}

.estudiantes .info {
	display: flex;
	justify-content: center;
	max-width: 1800px;
	flex-direction: row-reverse;
}

.estudiantes .info .col-6:first-child {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 80%;
	flex-direction: column;
	text-align: right;
}

.intsection.estudiantes_grado .col-12 .col-4 .col-12 h4 {
	font-family: "Montserrat";
	color: #3c726b;
	font-size: 24px !important;
}

.intsection.estudiantes_grado .col-12 .col-4 .col-12 h6 {
	font-size: 20px !important;
	margin-bottom: 20px !important;
	color: #3c726b;
	/* font-style: italic; */
	font-weight: normal;
}

.intsection.estudiantes_grado .col-12 .col-4 .col-12 img {
	width: 75px;
	margin-bottom: 10px;
	margin-left: -10px;
}

.intsection.estudiantes_grado .col-12 .col-4 .col-12 button {
	background: #3c726b;
	color: #fff;
	border: none;
}

.intsection.estudiantes_grado > .col-12:first-child {
	padding-bottom: 0 !important;
}

.intsection.estudiantes_master .col-12 .col-6 .col-12 h4 {
	font-family: "Montserrat";
	color: #3c726b;
	font-size: 28px !important;
}

.intsection.estudiantes_master .col-12 .col-6 .col-12 h6 {
	font-size: 20px !important;
	margin-bottom: 20px !important;
	color: #898989;
	/* font-style: italic; */
	font-weight: normal;
}

.intsection.estudiantes_master .col-12 .col-6 .col-12 img {
	width: 75px;
	margin-bottom: 10px;
	margin-left: -10px;
}

.intsection.estudiantes_master .col-12 .col-6 .col-12 button {
	background: #3c726b;
	color: #fff;
	border: none;
}

.intsection.estudiantes_master > .col-12:first-child {
	padding-bottom: 0 !important;
}

.intsection.estudiantes_master .col-12 .col-6 .col-12 {
	display: flex;
	flex-direction: column;
}

.intsection .col-12 .col-6 {
	padding-right: 45px !important;
}

.ampliado .col-12 {
	display: flex;
	flex-direction: column;
}

.ampliado .displayrow {
	flex-direction: row;
}

.ampliado .displayrow {
	padding: 0;
}

.ampliado .nopadding {
	padding: 0;
}

.ampliado .accordion-button:not(.collapsed) {
	color: var(--bs-accordion-active-color);
	background-color: #3c726b;
	box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
		var(--bs-accordion-border-color);
	color: #fff;
}

.ampliado .marron .accordion-button:not(.collapsed) {
	color: var(--bs-accordion-active-color);
	background-color: #a58609;
	box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
		var(--bs-accordion-border-color);
	color: #fff;
}

.ampliado .verdenormal .accordion-button:not(.collapsed) {
	color: var(--bs-accordion-active-color);
	background-color: #006d38;
	box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
		var(--bs-accordion-border-color);
	color: #fff;
}

.accordion-button:not(.collapsed)::after {
	background-image: url("../img/arrowup.svg") !important;
	transform: rotate(360deg);
}

.accordion-button.collapsed::after {
	background-image: url("../img/arrowup.svg") !important;
}

.accordion-button:not(.collapsed) {
	color: #ffffff;
}

.accordion-button:focus {
	z-index: 3;
	border-color: none !important;
	outline: 0;
	box-shadow: none !important;
}

.ampliado h4 {
	font-size: 20px !important;
	margin: 0;
}

.ampliado .accordion {
	width: 100%;
}

.accordion-button {
	margin: 0 !important;
	padding: 10px !important;
	color: #3c726b !important;
}

.marron .accordion-button {
	margin: 0 !important;
	padding: 10px !important;
	color: #a58609 !important;
}

.verdenormal .accordion-button {
	margin: 0 !important;
	padding: 10px !important;
	color: #006d38 !important;
}

.ampliado .accordion {
	border-color: #3c726b !important;
}

.accordion-item {
	border-color: #3c726b !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
	border-color: #3c726b !important;
}

.ampliado .accordion.marron {
	border-color: #a58609 !important;
}

.marron .accordion-item {
	border-color: #a58609 !important;
}

.marron .accordion-item:last-of-type .accordion-button.collapsed {
	border-color: #a58609 !important;
}

.ampliado .accordion.verdenormal {
	border-color: #006d38 !important;
}

.verdenormal .accordion-item {
	border-color: #006d38 !important;
}

.verdenormal .accordion-item:last-of-type .accordion-button.collapsed {
	border-color: #006d38 !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
	border: 0 !important;
}

.accordion-item {
	border: 0 !important;
}

.accordion-item:first-of-type .accordion-button {
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

.ampliado .accordion-button:not(.collapsed) {
	color: #fff !important;
}

.ampliado .accordion-body button {
	background: none !important;
	color: #3c726b !important;
	border: 2px solid #3c726b !important;
}

.ampliado .marron .accordion-body button {
	background: none !important;
	color: #a58609 !important;
	border: 2px solid #a58609 !important;
}

.ampliado .verdenormal .accordion-body button {
	background: none !important;
	color: #006d38 !important;
	border: 2px solid #006d38 !important;
}

.ampliado .accordion-body {
	padding: 5%;
}

.ampliado .accordion-body .col-12.mg45 {
	margin-top: 45px;
}

.intsection.ampliado .col-12 {
	padding: 0 !important;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.intsection.ampliado .col-12 .col-6 .col-12 {
	display: flex !important;
	flex-direction: column !important;
	justify-content: flex-start !important;
}

.ampliado .accordion-body {
	border: 1px solid #3c726b !important;
	border-top: 0 !important;
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	border: 1px solid #3c726b !important;
	border-top: 0 !important;
	background: none !important;
}

.accordion-item:first-of-type .accordion-button {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border: 1px solid #3c726b !important;
}

.ampliado .marron .accordion-body {
	border: 1px solid #a58609 !important;
	border-top: 0 !important;
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.marron .accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	border: 1px solid #a58609 !important;
	border-top: 0 !important;
	background: none !important;
}

.marron .accordion-item:first-of-type .accordion-button {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border: 1px solid #a58609 !important;
}

.ampliado .verdenormal .accordion-body {
	border: 1px solid #006d38 !important;
	border-top: 0 !important;
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.verdenormal .accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	border: 1px solid #006d38 !important;
	border-top: 0 !important;
	background: none !important;
}

.verdenormal .accordion-item:first-of-type .accordion-button {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
	border: 1px solid #006d38 !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.ampliado h6 {
	font-weight: bold !important;
	color: #3c726b !important;
}

.ampliado .marron h6 {
	font-weight: bold !important;
	color: #a58609 !important;
}

.ampliado .verdenormal h6 {
	font-weight: bold !important;
	color: #006d38 !important;
}

/*PDI*/

.pdi .info .col-6:last-child {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	height: 80%;
	flex-direction: column;
	text-align: left;
}

.pdi .info {
	display: flex;
	justify-content: center;
	max-width: 1800px;
	flex-direction: row-reverse;
}

.pdi .info .col-6:first-child {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 100%;
	flex-direction: column;
	text-align: right;
}

.pdi .intsection .col-12 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.pdi .intsection .col-6:last-child {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.pdi .intsection .col-12:first-child .col-6:last-child {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

/*ptgas*/

.ptgas .info {
	display: flex;
	justify-content: center;
	max-width: 1800px;
	flex-direction: row-reverse;
}

.ptgas .info .col-6:first-child {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 80%;
	flex-direction: column;
	text-align: right;
}

.ptgas .intsection .col-12 {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

.ptgas .intsection .col-6:last-child {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.ptgas .intsection .col-12:first-child .col-6:last-child {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

/*EMPRESAS*/

.empresas .info .col-6:last-child {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	height: 80%;
	flex-direction: column;
	text-align: left;
}

.empresas .info {
	display: flex;
	justify-content: center;
	max-width: 1800px;
	flex-direction: row-reverse;
}

.empresas .info .col-6:first-child {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 80%;
	flex-direction: column;
	text-align: right;
}

.empresas .intsection .col-12 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.empresas .intsection .col-6:last-child {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.empresas .intsection .col-12:first-child .col-6:last-child {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

/*Microcredenciales*/

.microcredenciales .info {
	display: flex;
	justify-content: center;
	max-width: 1800px;
	flex-direction: row-reverse;
}

.microcredenciales .info .col-6:first-child {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 80%;
	flex-direction: column;
	text-align: right;
}

.microcredenciales .intsection .col-12 {
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

.microcredenciales .intsection .col-6:last-child {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.microcredenciales .intsection .col-12:first-child .col-6:last-child {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.uja-footer__datos-contacto-inner {
	display: flex;
	justify-content: space-around;
}

.intsection.puzzle .col-12 {
	display: flex;
	flex-direction: row !important;
}

.organigrama {
	width: 100%;
}

.intsection.puzzle .col-12:first-child .col-6:last-child {
	/* align-items: center !important; */
	padding: 0 !important;
}

.puzzle .col-12.dflex .col-6 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.puzzle img {
	max-width: 550px;
}

.intsection.puzzle .col-12:first-child .col-6:last-child > div {
	width: 550px !important;
	height: 309px !important;
}

#player {
	width: 550px !important;
	height: 309px !important;
}

/* CURSOS DE VERANO */
.cartelesverano {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	justify-content: space-between;
	gap: 2rem;
}

.cursoverano {
	border-radius: var(--bs-border-radius-lg);
	width: 100%;
}

.cartelesverano > div {
	transition-duration: 0.3s;
}

.cartelesverano > div:hover {
	cursor: pointer;
	transform: translateY(-5px);
}

.cursosdeverano .contslider2 .info h1 {
	font-size: 300%;
	color: #fff;
	font-weight: bold;
	margin: 0;
}

.bannerslider {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.modal-body {
	padding: 0 !important;
}

.modal-close {
	position: absolute;
	border: none;
	width: 48px;
	object-fit: cover;
	margin: 0 !important;
	right: 0;
}

.contenidomodal {
	display: flex;
	flex-direction: row;
	aspect-ratio: 420/297;
}

.textomodal {
	display: flex;
	width: 100%;
	justify-content: space-between !important;
}

.contenido-cursos {
	overflow-y: auto;
}

.textomodal ul {
	padding: 0;
	margin: 0;
}

.textomodal .curso-item {
	display: flex;
	flex-direction: column;
	color: #555;
	list-style: none;
	border-bottom: 1px solid black;
	padding: 1rem;
	font-size: 1rem;
	font-style: italic;
	font-weight: 400;
	transition-duration: 0.3s;
}

.textomodal .curso-item:last-of-type {
	border-bottom: none;
}

.textomodal .curso-item:hover {
	background-color: #d3e1db;
	cursor: pointer;
}

.textomodal .cursos-container {
	padding-right: 2rem;
}

.textomodal h3 {
	color: #000;
	font-size: 1.2rem;
	font-weight: 700;
	font-style: normal;
}

.textomodal h4 {
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	font-style: normal;
	color: black;
	font-size: 1rem !important;
	margin: 0;
	margin-top: 10px;
	height: min-content;
}

.textomodal .ponentes-container {
	display: flex;
	flex-direction: row;
	gap: 5px;
	margin-bottom: 10px;
}

.textomodal .ponente {
	font-style: italic;
	padding-bottom: 1rem;
}

.textomodal .ponente::after {
	content: ",";
}

.textomodal .ponente:last-of-type::after {
	content: "";
}

.logos-patrocinadores {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	border-top: 1px solid black;
	position: relative;
	bottom: 0;
	width: 100%;
	height: min-content;
	margin: 0;
	padding-top: 32px !important;
	box-shadow: 0 -20px 25px -25px #00000055;
}

.logos-patrocinadores li {
	list-style: none;
	display: flex;
}

.logos-patrocinadores img {
	max-height: 50px;
	width: auto;
	align-self: center;
}

.imgmodal {
	width: 50%;
	aspect-ratio: 210/297;
	border-radius: var(--bs-border-radius-lg) 0 0 var(--bs-border-radius-lg);
}

.contenidomodal h1 {
	/* font-size: 45px!important; */
	color: #000000 !important;
	font-weight: bold !important;
	/* margin:0!important; */
}

.contenidomodal h2 {
	font-size: 20px !important;
	color: #555555 !important;
	font-style: italic;
	margin-bottom: 2rem;
}

.textomodal {
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: left;
	padding: 2rem;
	padding-top: 3.5rem;
}

.textomodal .botoninfo {
	font-style: normal !important;
	text-decoration: none;
	font-size: 1.25rem !important;
	width: auto;
	border: none;
	padding: 0.3rem 1rem;
	align-self: flex-end;
	margin: 0;
}

.textomodal .botoninfo {
	transition-duration: 0.3s;
	background-color: #006d38;
	color: white;
}

.textomodal .botoninfo:hover {
	background-color: #0a8248;
}

/* 15/07/2024 Cursos PDI */

.divider--vertical {
	border-left: 1px solid rgb(240, 240, 240);
	min-height: 100%;
}

.divider--horizontal {
	border-top: 2px solid rgb(210, 210, 210);
	min-width: 100%;
}

.gradient-background--45deg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(
		45deg,
		rgba(0, 0, 0, 1) 15%,
		rgba(0, 0, 0, 0) 100%
	);
	z-index: -1;
}

.course-browser__header {
	width: 100%;
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
	align-items: flex-start;
	padding-left: calc(32px + 2rem + 1px + 2rem);
	justify-content: space-between;
}

.course-type-selector {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	list-style: none;
	margin: 0;
	padding: 0;

	& button {
		display: flex;
		width: auto;
		font-size: 1rem !important;
		font-weight: 600;
		color: white;
		border: none;
		padding: 8px 16px;
		margin: 0 !important;
		border-radius: 10px;
		transition: all 0.3s;
	}
}

.pdi ~ * .course-type-selector button {
	background-color: #debe3f;

	&.active {
		background-color: #a58609;
	}

	&:hover:not(.active) {
		background-color: #c2a018;
	}
}

.estudiantes ~ * .course-type-selector button {
	background-color: #3c726b;

	&.active {
		background-color: #205a52;
	}

	&:hover:not(.active) {
		background-color: #2f5f58;
	}
}

.course-browser__searchmenu {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 1rem;
}

.course-browser__filter,
.course-browser__searchbar {
	height: 34px;
	display: flex;
	align-items: center;
	gap: 1rem;

	& input {
		height: 100%;
		border: none;
		border-bottom: 2px solid var(--color-primary);
		padding: 0.35rem 1rem;
		padding-left: 1.5rem;
		width: 300px;
		background-color: rgb(240, 240, 240);
		transition: all 0.3s;

		&:focus {
			outline: none;
			border-bottom: 2px solid var(--color-primary-light);
		}

		&:hover {
			background-color: rgb(230, 230, 230);
		}
	}

	& svg {
		cursor: pointer;
		height: 70%;

		& * {
			fill: black;
		}
	}
}

.course-browser__filters--wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: auto;
	justify-content: flex-end;
	gap: 2rem;
}

.course-browser__filters--item {
	display: flex;
	align-items: center;
	gap: 1rem;

	& select {
		padding: 0.25rem 0.5rem;
		background-color: rgb(240, 240, 240);
		border: none;
		outline: none;
		border-radius: 10px;
	}
}

.course-browser {
	position: relative;
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 100px;
}

.course-browser__navigation {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	gap: 2rem;
	padding: 1rem 0;
	transition: all 0.3s;

	& img {
		width: 32px;
		opacity: 0.25;
		transition: all 0.3s;
	}

	& h3 {
		font-size: 1rem;
		display: none;
		margin: 0;
		font-weight: bold;
		opacity: 0.25;
		padding: 0 2rem 0 1rem;
	}

	& .active {
		& img,
		h3 {
			opacity: 1;
		}
	}
}

.course-browser__tooltip {
	position: relative;
}

.course-browser__tooltip__text {
	display: flex;
	align-items: center;
	visibility: hidden;
	transition: all 0.3s;
	background: black;
	opacity: 0;
	color: white;
	padding: 0;
	font-size: 0.25rem;
	border-radius: 5px;
	position: absolute;
	top: 0;
	left: 100%;
	z-index: 1;
	width: max-content;
	height: 100%;
}

.course-browser__tooltip:hover .course-browser__tooltip__text {
	font-size: 1rem;
	padding: 8px 16px;
	visibility: visible;
	opacity: 1;
}

.course-browser__navigation-item {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding-right: 2rem;

	&.active:hover {
		& img,
		h3 {
			opacity: 1;
		}
	}

	&:hover {
		& img,
		h3 {
			opacity: 0.5;
		}
	}
}

.course-browser__navigation-item:first-of-type {
	margin-bottom: 4rem;
}

.course-browser__content {
	display: flex;
	flex-direction: column;
	flex: 1 1 0;
	padding-left: 2rem;
}

.course-browser__recount {
	font-size: 1rem !important;
	padding: 1rem 2rem;
	background-color: black;
	color: white !important;
	border-radius: 10px;
	margin-top: 2rem;
	margin-bottom: 2rem;
	font-style: italic;
	font-size: 1rem;

	& span {
		font-weight: bold;
	}
}

.category-info {
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	padding: 2rem;
	gap: 3rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	& h2 {
		font-size: 3rem;
		color: white;
		font-weight: bold !important;
		width: 50%;
	}

	& h3 {
		font-size: 1rem !important;
		font-weight: normal;
		font-style: italic;
		margin: 0;
		color: white;

		& span {
			font-weight: bold;
		}
	}

	& p {
		font-size: 1rem;
		color: white !important;
		width: 50%;
		margin: 0;

		& ul {
			list-style: "· ";
		}

		& a {
			font-weight: bold;
			color: white;
		}
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: -1;
	}
}

.card-wrapper--horizontal {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: flex-start;
	width: 100%;
	scroll-snap-type: x mandatory;
	border-radius: 10px;
	height: 500px;
}

.card-wrapper--vertical {
	display: grid;
	grid-template-columns: repeat(5, minmax(0, 1fr));
	gap: 2rem;
	width: 100%;
}

div:has(> .card-wrapper__loading),
div:has(> .card-wrapper__empty-results) {
	flex: 1;
}

.card-wrapper__loading,
.card-wrapper__empty-results {
	display: flex;
	flex-direction: column;
	gap: 4rem;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100% !important;
	flex: 1 0 auto;

	& svg {
		width: 10%;
		opacity: 0.15;

		& * {
			fill: black;
		}
	}

	& span {
		text-align: center;
		font-size: 1.5rem;
		font-weight: bold;
		opacity: 0.30;
	}

	& .loading-image {
		object-fit: cover;
		width: 15%;
		animation: glow 1s infinite;
		z-index: 1;
	}
}

@keyframes glow {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.5;
	}
}

.card-wrapper__card {
	position: relative;
	width: 100%;
	transition: all 0.6s;
	cursor: pointer;

	&.active {
		padding: 0.5rem;
		padding-bottom: 4rem;

		& h2 {
			opacity: 1;
		}
	}

	& h2 {
		padding: 0.5rem;
		color: white;
		font-size: 1.2rem !important;
		height: 0;
		opacity: 0;
		transition: all 0.6s;
		transition-delay: 0.1s;
		display: none;
	}

	& .cartel-builder__wrapper {
		border-radius: 10px;
		overflow: hidden;
	}

	& .modal-cursos-hover__wrapper {
		animation: hover--opacity 0.3s;
	}

	&:has( .closed) {
		cursor: default !important;
	}
}

.course-browser__filters--container {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: flex-end;

	max-height: 0;
	/* overflow-y: hidden; */
	transition: max-height 0.5s ease-in-out;

	&.visible-drop {
		max-height: 1000px;
	}

	&.overflow-hidden{
		overflow-y: hidden;
	}
}

.course-browser__filters--content {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	align-items: flex-end;
	margin-bottom: 2rem;
}

span.clear-filters {
	display: flex;
	gap: 0.5rem;
	align-items: center;
	height: 34px !important;
	border-radius: 50px;
	background-color: rgb(240, 240, 240);
	padding: 0.75rem;
	width: fit-content;
	min-width: max-content;
	cursor: pointer;
	transition: background-color 0.3s;
	margin-top: -0.2rem;

	& svg {
		height: 1rem !important;
		width: 1rem !important;
	}

	&:hover {
		background-color: rgb(220, 220, 220);
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}

/* Filtro - Multiselect */

.ms-dropdown {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	position: relative;
	align-items: center;
}

.course-browser__filters--item label {
	font-weight: bold;
}

.ms-dropdown__header {
	font-size: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 1rem;
	border-radius: 10px;
	cursor: pointer;
	width: 200px;
	max-width: 200px;
	height: 32.5px;
	overflow: hidden;
	background-color: rgb(240, 240, 240);
	transition: background-color 0.3s;

	& span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	& svg {
		height: 1rem;
		min-height: 1rem;
		width: 1rem;
		min-width: 1rem;
		transform: rotate(180deg);

		& * {
			fill: black;
		}
	}

	&:hover {
		background-color: rgb(220, 220, 220);
	}
}

.ms-dropdown.open {
	& .ms-dropdown__header {
		border-radius: 10px 10px 0 0;
	}

	& .ms-dropdown__list {
		border-radius: 0 0 10px 10px;
	}
}

.ms-dropdown__list {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 100%;
	right: 0;
	width: 200px;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.25);
	overflow: hidden;
	z-index: 100;

	& label {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		padding: 0.5rem 0.75rem;
		cursor: pointer;

		&:has(input:checked) {
			background-color: rgb(230, 230, 230);
		}
	}
}

/* Fin Filtro - Multiselect */

/* Filtro - Slider */

label:has(~ .range-slider) {
	font-weight: bold;
}

.range-slider {
	padding: 1rem 1.25rem;
	display: flex;
	gap: 1rem;
	align-items: center;
	height: 32.5px;
	width: 250px;
	position: relative;
	background-color: rgb(240, 240, 240);
	border-radius: 10px;
}

.range-values {
	display: flex;
	flex-direction: row;
	gap: 0.25rem;
	justify-content: flex-end;
	color: rgb(120, 120, 120);

	& span {
		color: black;
	}
}

.range-thumb {
	border: 3px solid rgb(240, 240, 240);
	outline: none;
}

.range-interval-wrapper {
	position: absolute;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	transform: translateY(-30%);
	z-index: -1;
}

.range-interval:not(:last-child) {
	width: 25%;
	height: 10px;
	border-right: 4px solid var(--color-primary);
	transform: translateX(2px);
}

.course-browser__filters--calendar {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 32.5px;
	background-color: rgb(240, 240, 240);
	border-radius: 10px;
	width: fit-content;
	position: relative;

	& .calendar-show,
	.calendar-show > * {
		position: absolute;
		right: 0;
	}

	& .calendar-show > * {
		top: 16px;
	}

	& .start-date {
		position: relative;
		width: 100%;
		padding: 0 1rem;
		flex-grow: 1;
		display: flex;
		flex-direction: row;
		align-items: center;

		& svg {
			height: 1.5rem;
		}

		&:hover svg * {
			fill: var(--color-dark) !important;
		}
	}

	& .end-date {
		position: relative;
	}

	& button {
		background: transparent;
		border: none;
		gap: 1rem;
		padding: 0.5rem 1.5rem;
		color: var(--color-dark);
	}

	& span {
		font-family: "Ubuntu", sans-serif;
		flex: 1 1 0;
		display: flex;
		color: var(--color-dark);
	}

	&:hover svg * {
		fill: var(--color-dark) !important;
	}
}

/* Fin Filtro - Slider */

@keyframes hover--opacity {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.card-wrapper__content {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	transition: all 0.6s;
}

*:has(> .modal-content) {
	justify-content: center;
}

.modal-content {
	max-width: 700px;
	border-radius: 10px !important;
	overflow: hidden;
}

.modal-cursos__wrapper {
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	overflow: hidden;

	& .cartel-builder__wrapper {
		z-index: 0;
		border-radius: 10px 0 0 0;
		overflow: hidden;
	}

	& .modal-cursos__close {
		position: absolute;
		background-color: white;
		border-radius: 40px;
		border: none;
		margin: 0 !important;
		top: 0.5rem;
		right: 0.5rem;
		height: 24px;
		width: 18px;
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: center;

		& img {
			width: 1rem;
			height: 1rem;
		}
	}
}

.modal-cursos__container {
	padding: 2rem;
}

.modal-cursos__content--top {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
	background-color: white;
	border-radius: 0 0 10px 10px;

	& > * {
		flex: 1 0 0;
	}

	& .modal-cursos__container {
		padding-bottom: 0;
	}

	& .modal-cursos__content {
		padding-top: 2rem;
	}

	& .button-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		flex: 1 0 0;
	}
}

.modal-cursos__content--bottom {
	padding: 2rem;
}

.modal-cursos__content-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
}

.modal-cursos__content {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	overflow-y: auto;
	justify-content: flex-end;
	height: 100%;

	& ol {
		margin-left: 0.5rem !important;
	}

	& p,
	span,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: black !important;
	}
}

.modal-cursos__content--title {
	color: black !important;
	font-size: 1.5rem !important;
	font-weight: bold;
	margin: 0;
}

.modal-cursos__content--subtitle {
	color: black !important;
	font-size: 1.1rem !important;
	color: rgb(60, 60, 60) !important;
	margin: 0;
}

.modal-cursos-hover__wrapper .modal-cursos__content--price {
	font-size: 1.2rem;
}

.modal-cursos__content--price {
	color: black !important;
	font-size: 1.5rem;
	font-weight: bold;
	margin: 0;
}

.modal-cursos__content--date {
	font-style: italic;
	font-weight: 400;
	font-size: 0.9rem;
	margin: 0;
	color: rgb(60, 60, 60) !important;

	& ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	& span {
		font-weight: bold;
	}
}

.modal-cursos__content--description {
	font-size: 0.9rem !important;
	margin: 0;
	color: rgb(60, 60, 60) !important;

	& ul {
		list-style: "· ";
		margin: 0;
		padding: 0.5rem;
	}

	& ol {
		margin: 0;
		padding: 0.5rem;
	}

	& a {
		font-weight: bold;
		color: white;
	}
}

.modal-cursos__footer {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.modal-cursos__ponentes--container,
.modal-cursos__taxonomies--container {
	display: inline-flex;
	flex-direction: row;
	gap: 0.5rem;
	color: rgb(120, 120, 120);
	font-size: 0.9rem;
}

.modal-cursos__ponentes,
.modal-cursos__taxonomies {
	width: auto;
	display: flex;
	gap: 0.25rem;
	list-style: none;
	padding: 0;
	margin: 0;
	color: black;
	font-weight: bold;
	font-size: 0.9rem;
}

.modal-cursos__cta {
	align-self: flex-end;
	border-radius: 10px;
	padding: 0.5rem 1rem !important;
}

.modal-cursos__cta.finished {
	opacity: 0.5;
	pointer-events: none;
}

.modal-cursos-hover__wrapper {
	position: absolute;
	top: 0;
	width: 100%;
	background-color: white;
	border-radius: 10px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
	z-index: 20;
	opacity: 1;

	& .cartel-builder__wrapper {
		border-radius: 10px 10px 0 0;
		overflow: hidden;
	}
}

.modal-cursos-hover__content {
	cursor: default;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 1rem;
}

.modal-cursos-hover__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal-cursos__content--modalidad,
.modal-cursos-hover__modalidad {
	color: var(--color-primary);
	font-weight: bold;
	font-size: 0.9rem;
}

.modal-cursos__content--ects,
.modal-cursos-hover__ects {
	padding: 0.25rem 0.5rem;
	border-radius: 5px;
	background-color: black;
	border: 1px solid black;
	color: white;
	width: max-content;
	font-size: 0.9rem;
}

.modal-cursos-hover__hours {
	padding: 0.25rem 0.5rem;
	border-radius: 5px;
	background-color: white;
	border: 1px solid black;
	color: black;
	width: max-content;
	font-size: 0.9rem;
}

.modal-cursos__content--guiadocente,
.modal-cursos__content--edpuzzle {
	height: 2rem;
	width: 2rem;
	overflow: hidden;
	opacity: 0.8;
	transition: all 0.3s;

	& * {
		height: 100%;
		width: 100%;
	}

	&:hover {
		opacity: 1;
	}
}

.modal-cursos__content--edpuzzle {
	width: 4rem;
	height: 4rem;
}

.modal-cursos-hover__taxonomies {
	width: auto;
	display: flex;
	gap: 0.5rem;
	list-style: none;
	padding: 0;
	margin: 0;
	color: rgb(120, 120, 120);
}

.modal-cursos-hover__ponentes {
	& span {
		font-weight: bold;
	}
}

.modal-cursos__cta,
.modal-cursos-hover__cta {
	text-decoration: none;
	margin: 1rem 0 0 0;
	border-radius: 5px;
	border: none;
	background-color: var(--color-primary);
	color: white;
	font-weight: bold;
	width: max-content;
	align-self: flex-end;
	padding: 0.5rem 1rem;
	transition: all 0.3s;

	&:hover {
		background-color: var(--color-primary-dark);
	}
}

.flex-vertical {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.flex-horizontal {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
}

.space-between {
	justify-content: space-between;
}
/* Fin de Cursos PDI */

.category-info__filters--list {
	font-size: 1rem;
	font-weight: bold;
	color: white;
	font-style: italic;

	& span {
		font-weight: 400;
	}
}

/* Nuevo Navbar 31/07/2024 */

.lang-switch ul {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 12px;
}

.lang-switch ul li {
	cursor: pointer;
	font-size: 12px;

	&.active {
		font-weight: bold;
		color: #005f32;
	}
}

header .container-fluid {
	padding: 2rem 5% !important;
	max-width: 1800px;
	width: 100%;
	flex-direction: column;
	align-items: flex-start !important;
}

.sub-menu {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0.5rem;
	min-width: 200px;
	max-width: 300px;
	border-radius: 10px;
	background-color: rgb(44, 43, 43);
	margin-top: 47px;
	opacity: 0;
	transform: scale(0.95);
	z-index: 1;
	transition: all 0.3s ease;

	&.open {
		z-index: 999;
	}

	& li {
		width: 100%;
	}

	& li a.nav-link {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		padding: 0.5rem !important;
		border-radius: 5px;
		width: 100%;
		transition: all 0.3s ease;
		font-weight: 600;
		color: white !important;
		text-decoration: none;
		gap: 1rem;

		&.active {
			color: #38ca8a !important;
		}

		& svg,
		img {
			flex: 0 0 auto;
			width: 1.75rem;
		}

		& .sub-menu__content {
			flex: 1 1 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
		}

		&:hover {
			background-color: rgb(73, 73, 73);

			& .sub-item-description {
				color: white;
			}
		}
	}
}

.sub-menu.open {
	opacity: 1;
	transform: scale(1);
	transition: all 0.3s ease;
}

.sub-item-description {
	font-weight: 300;
	font-size: 0.8rem;
	color: rgb(202, 192, 192);
	font-size: 14.4px !important;
}

.horizontal-divider {
	border-top: 1px solid rgb(240, 240, 240);
	width: 100%;
}

*:has(> .navbar-nav) {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
}

.navbar-nav {
	display: flex;
	gap: 2rem;
	padding-top: 2rem;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	z-index: 999;
}

.navbar-nav li {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
}

.navbar-nav li .nav-link {
	position: relative;
	cursor: pointer;
	font-size: 1;
	color: black;
	font-weight: 600;
	text-decoration: none;
	display: flex;
	gap: 0.75rem;
	flex-direction: row;
	align-items: center;
}

.navbar-nav .sub-menu li .nav-link svg {
	height: 1.25rem;
}

.navbar-nav .arrow-icon {
	transform: rotate(180deg);
	width: 1rem;

	& * {
		fill: black;
	}
}

/* Fin de Navbar 31/07/2024 */

/* Fixing 01/08/2024 */

.uja-footer__logo-uja {
	display: flex;
	align-items: center;

	& img {
		margin: 0 !important;
	}
}

.contslider .info {
	display: flex;
	align-items: center;
	justify-content: space-between;

	& .col-6 {
		width: 40%;
		max-width: 40%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	& .col-6:has(> .sliderimg) {
		height: 100%;
		position: relative;
	}

	/* & .col-6:has(> .sliderimg):before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		mix-blend-mode: color;
		z-index: 1 !important;
	} */

	& .sliderimg {
		max-width: 100%;
		width: 400px;
		object-fit: cover;

		&.genia {
			padding-top: 4rem;
			padding-bottom: 4rem;
		}
	}
}

.sub-menu {
	display: none;
}

.sub-menu.open {
	display: flex;
}

.flex-row {
	display: flex;
	flex-direction: row;
	gap: 1rem;
}

.flex-row__header {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.desktop-hidden {
	display: none;
}

/* Fin de Fixing 01/08/2024 */

/* ACORDEON */

.layout-acordeon-container {
	width: 100%;

	& .acordeon-trigger {
		background-color: #205383 !important;
	}
}

.acordeon-container,
.layout-acordeon-container {

	display: flex;
	flex-direction: column !important;
	gap: 1rem;

	& .acordeon-trigger {
		position: relative;
		margin: 0 !important;
		padding: 1rem;
		background-color: #822633;
		color: white;
		border-radius: 5px;
		transition: all 0.3s ease;
		width: 100%;
		display: flex;
		gap: 1rem;

		& .img-container {
			max-width: 50px;
			max-height: 50px;
			display: flex;
			align-items: center;

			& img {
				max-width: 100%;
				max-height: 100%;
				object-fit: contain;
			}
		}

		& h3 {
			font-size: 1.5rem;
			font-weight: 400;
			font-style: italic;
		}

		&:hover {
			background-color: #711c27;
			cursor: pointer;
		}
	}

	& .acordeon-trigger::after {
		content: "▲";
		font-size: 12px;
		position: absolute;
		right: 1rem;
		top: calc(50% - 6px);
	}

	& .acordeon-trigger.cerrado::after {
		transform: rotate(180deg);
	}
	
	& .acordeon-trigger ~ div {
		padding: 2rem;
		font-size: 14px;

		& a {
			text-decoration: none;
			color: var(--color-primary);
			font-weight: 700;
		}
	}

	& .acordeon-trigger.cerrado ~ div {
		display: none;
	}
}

/* FIN ACORDEON */

@media only screen and (max-width: 1700px) {
	.contenidomodal {
		height: 600px;
	}

	.info .col-6 h2 {
		font-size: 30px;
		color: #fff;
		font-weight: bold;
		margin: 0;
	}

	.contslider .info {
		padding: 0% 5%;
	}

	.empresas .contslider .info {
		padding: 0 5%;
	}

	.ptgas .contslider .info {
		padding: 0 5%;
	}

	.microcredenciales .contslider .info {
		padding: 0 5%;
	}

	.ampliado .info {
		padding: 0 5%;
	}

	.empresas.innovacion .contslider .info {
		padding: 0 5%;
	}
}

@media only screen and (max-width: 1700px) {
	.contvolver {
		width: 100%;
		padding: 0 45px;
	}

	.contpanelcookies {
		width: 70% !important;
	}

	.migapan.error {
		padding: 0 35px !important;
	}

	.error .contvolver {
		padding: 0 35px !important;
	}
}

@media only screen and (max-width: 1400px) {
	.textomodal .ponentes-container {
		flex-direction: column;
		gap: 0;
	}

	.textomodal .ponente {
		padding-bottom: 0;
	}

	.contpanelcookies {
		width: 90% !important;
	}

	.migapan.error {
		padding: 0 35px !important;
	}

	#player {
		width: 550px !important;
		height: 309px !important;
	}

	.intsection.puzzle .col-12:first-child .col-6:last-child > div {
		width: 550px !important;
		height: 309px !important;
	}

	.intsection.puzzle .col-12:first-child .col-6:last-child {
		align-items: flex-start !important;
	}
}

@media only screen and (max-width: 1200px) {
	.card-wrapper--vertical {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	main:has(.inicio) .contnovedades {
		flex-direction: row;
		padding: 0 !important;
	}

	.textomodal .ponentes-container {
		flex-direction: column;
		gap: 0;
	}

	.textomodal .ponente {
		padding-bottom: 0;
	}

	.cartelesverano {
		grid-template-columns: repeat(3, 1fr);
	}

	.empresas {
		overflow: hidden;
		max-height: inherit;
	}

	.flecha {
		margin: 10px 0 0 5px;
	}

	.migapan.error {
		padding: 0 35px !important;
	}

	.ptgas .info .col-6:last-child {
		align-items: center;
	}

	.info .col-6:nth-child(2) {
		display: flex;
		justify-content: center;
	}

	.intsection.puzzle .col-12 {
		display: flex;
		flex-direction: column !important;
	}

	.info .col-6 h2 {
		font-size: 30px;
		color: #fff;
		font-weight: bold;
	}

	.info .col-6 p {
		color: #fff;
		font-size: 22px;
		line-height: 26px;
		margin: 5px 0;
	}

	.contnovedades {
		padding: 0;
	}

	.estudiantes .info {
		display: flex;
		justify-content: center;
		max-width: 100%;
		flex-direction: column-reverse;
	}

	.estudiantes .info .col-6:first-child {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: auto;
		flex-direction: column;
		text-align: right;
	}

	.estudiantes .info .col-6:first-child {
		display: flex;
		align-items: center;
		justify-content: center;
		height: auto;
		flex-direction: column;
		text-align: center;
	}

	.intsection.ampliado .col-12 {
		padding: 0 !important;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
	}

	.intsection.ampliado .col-12 .col-4 {
		width: 100%;
	}

	.intsection.ampliado .col-12 .col-4 .col-12 {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 0 0 0 0 !important;
		justify-content: center;
	}

	.estudiantes .intsection .col-12 {
		min-height: auto;
	}

	.estudiantes .intsection .col-4 {
		min-height: auto;
		margin: 25px 0 0 0;
	}

	.estudiantes .intsection .col-4:first-child {
		min-height: auto;
		margin: 0 0 0 0;
	}

	.estudiantes .intsection .col-12:last-child .col-4:first-child {
		min-height: auto;
		margin: 25px 0 0 0;
	}

	.ampliado .accordion-body .col-12.mg45 {
		margin-top: 0;
	}

	.intsection .col-12 .col-6 {
		padding-right: 0 !important;
		margin: 25px 0;
		width: 100%;
	}

	.intsection .col-12 .col-6:first-child {
		padding-right: 0 !important;
		margin: 0 0;
		width: 100%;
	}

	.estudiantes .info {
		display: flex;
		justify-content: center;
		max-width: 100%;
		flex-direction: column-reverse;
		align-items: center;
	}

	.info .col-6 p {
		color: #fff;
		font-size: 22px;
		line-height: 26px;
		margin-top: 15px;
		width: 100%;
	}

	.estudiantes .info .col-6:first-child {
		display: flex;
		align-items: center;
		justify-content: center;
		height: auto;
		flex-direction: column;
		text-align: center;
		width: 90%;
	}

	.pdi .intsection .col-12 {
		min-height: auto;
	}

	.pdi .intsection .col-4 {
		min-height: auto;
		margin: 25px 0 0 0;
	}

	.pdi .intsection .col-4:first-child {
		min-height: auto;
		margin: 0 0 0 0;
	}

	.pdi .intsection .col-12:last-child .col-4:first-child {
		min-height: auto;
		margin: 25px 0 0 0;
	}

	.pdi .info {
		display: flex;
		justify-content: center;
		max-width: 100%;
		flex-direction: column;
		align-items: center;
		height: 100%;
		padding: 0;
	}

	.pdi .info .col-6:last-child {
		display: flex;
		align-items: center;
		justify-content: center;
		height: auto;
		flex-direction: column;
		text-align: left;
		width: 90%;
		text-align: center;
	}

	.ptgas .intsection .col-12 {
		min-height: auto;
	}

	.ptgas .intsection .col-4 {
		min-height: auto;
		margin: 25px 0 0 0;
	}

	.ptgas .intsection .col-4:first-child {
		min-height: auto;
		margin: 0 0 0 0;
	}

	.ptgas .intsection .col-12:last-child .col-4:first-child {
		min-height: auto;
		margin: 25px 0 0 0;
	}

	.ptgas .info {
		display: flex;
		justify-content: center;
		max-width: 100%;
		flex-direction: column-reverse;
		align-items: center;
	}

	.ptgas .info .col-6:last-child {
		display: flex;
		padding: 0;
		max-height: 100%;
		justify-content: center;
		background: url(../img/tiraazul2.svg);
		z-index: 99999;
		position: relative;
		overflow: hidden;
		flex-direction: column;
	}

	.ptgas .info .col-6:first-child {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		width: 100%;
		height: auto;
	}

	.empresas .intsection .col-12 {
		min-height: auto;
	}

	.empresas .intsection .col-4 {
		min-height: auto;
		margin: 25px 0 0 0;
	}

	.empresas .intsection .col-4:first-child {
		min-height: auto;
		margin: 0 0 0 0;
	}

	.empresas .intsection .col-12:last-child .col-4:first-child {
		min-height: auto;
		margin: 25px 0 0 0;
	}

	.empresas .info {
		display: flex;
		justify-content: center;
		max-width: 100%;
		flex-direction: column;
		align-items: center;
	}

	.empresas .info .col-6:last-child {
		display: flex;
		padding: 0;
		max-height: 100%;
		justify-content: center;
		z-index: 99999;
		position: relative;
		overflow: hidden;
		flex-direction: column;
		height: auto;
		width: 100%;
		text-align: center;
		align-items: center;
	}

	.empresas .info .col-6:first-child {
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding: 0 !important;
		margin: 0;
		position: relative;
	}

	.inicio .contslider {
		max-height: 100% !important;

		& .col-6 {
			max-width: 100% !important;
		}
	}

	main > div:not(.inicio) .contslider {
		max-height: fit-content;
	}

	main > div:not(.inicio) .info {
		justify-content: center;
		position: relative;
		height: fit-content !important;

		& .col-6 {
			position: relative !important;
			align-self: flex-start;
			align-items: flex-start !important;
			text-align: left !important;
			text-align: left;
			z-index: 10 !important;
			min-width: 75%;
			line-height: 1.5rem;
			max-height: fit-content;
			padding: 50px 0;
		}

		& .col-6:has(img) {
			position: absolute !important;
			display: flex !important;
			align-items: flex-end !important;
			opacity: 0.25;
			top: 0;
			right: 5%;
			width: auto !important;
			z-index: 0 !important;
		}
	}

	.contnovedades {
		flex-direction: column;
		padding: 0 5% !important;
	}

	.novedad.f2 > * {
		width: 100%;
	}

	.info .col-6 p {
		padding-right: 0;
	}

	.empresas .info .col-6 p {
		padding-right: 0;
	}

	.microcredenciales .info .col-6:first-child {
		height: 100%;
	}

	.microcredenciales .intsection .col-12 {
		min-height: auto;
	}

	.microcredenciales .intsection .col-4 {
		min-height: auto;
		margin: 25px 0 0 0;
	}

	.microcredenciales .intsection .col-4:first-child {
		min-height: auto;
		margin: 0 0 0 0;
	}

	.microcredenciales .intsection .col-12:last-child .col-4:first-child {
		min-height: auto;
		margin: 25px 0 0 0;
	}

	.microcredenciales .info {
		display: flex;
		justify-content: center;
		max-width: 100%;
		flex-direction: column-reverse;
		align-items: center;
	}

	.microcredenciales .info .col-6:last-child {
		display: flex;
		padding: 0;
		max-height: 100%;
		justify-content: center;
		z-index: 99999;
		position: relative;
		overflow: hidden;
		flex-direction: column;
		align-items: center;
	}

	.microcredenciales .info .col-6:first-child {
		height: auto;
	}

	.microcredenciales .info .col-6:first-child {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		flex-direction: column;
		text-align: center;
	}
}

@media only screen and (max-width: 1100px) {
	.textomodal .ponentes-container {
		flex-direction: column;
		gap: 0;
	}

	.textomodal .ponente {
		padding-bottom: 0;
	}

	.nav-item {
		margin: 0 5px;
	}

	.migapan.error {
		padding: 0 35px !important;
	}
}

@media only screen and (max-width: 991px) {
	main:has(.inicio) {
		gap: 0;

		& .contnovedades {
			padding: 0 !important;
			flex-direction: column;
		}
	}

	.flex-column__header {
		flex-direction: row !important;
		justify-content: space-between;
		width: 100%;
		align-items: center;
	}

	#botonmenu,
	.navbar-toggler-icon {
		width: 24px !important;
		height: 24px !important;
		margin: 0;
		padding: 0;
	}

	.mobile-hidden {
		display: none !important;
	}

	.logo img {
		margin: 0 !important;
	}

	.navbar-nav {
		width: 100%;
		position: absolute;
		top: 177.38px;
		right: 0;
		left: 0;

		background-color: black !important;

		& .nav-link {
			color: white !important;

			& polygon,
			path {
				fill: white !important;
			}
		}
	}

	.modal-cursos__img {
		width: 100%;
	}

	.textomodal .ponentes-container {
		flex-direction: column;
		gap: 0;
	}

	.textomodal .ponente {
		padding-bottom: 0;
	}

	.cursosdeverano .bannerslider .col-3 {
		width: 50% !important;
	}

	.cursosdeverano .bannerslider .info {
		align-items: flex-start;
	}

	.contenidomodal {
		flex-direction: column;
		height: 50%;
	}

	.imgmodal {
		width: 100%;
		border-radius: var(--bs-border-radius-lg) var(--bs-border-radius-lg) 0 0;
	}

	.textomodal .cursos-container {
		overflow-y: hidden;
	}

	.contvolver {
		padding: 15px 15px 0 15px;
	}
	.cont_footer .col-4:first-child {
		order: 2;
	}

	.cont_footer .col-4 {
		width: 100%;
		margin-bottom: 25px;
		text-align: center;
		display: flex;
		justify-content: center;
		margin: 25px 0 !important;
	}

	footer .col-4:last-child ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		list-style: none;
	}

	footer .col-4:last-child ul {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	footer .col-4:first-child ul {
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		align-items: center;
		list-style: none;
	}

	.cont_footer {
		margin: 0 auto;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5%;
		width: 100% !important;
		flex-direction: column;
	}

	.info {
		display: flex;
		flex-direction: column-reverse;
		flex-wrap: nowrap;
		align-items: center;
	}

	.contslider {
		background: #00a65d;
	}

	.info .col-6:first-child {
		display: flex;
		align-items: center;
		justify-content: center;
		height: auto;
		flex-direction: column;
		width: 100%;
		text-align: center;
	}

	.info .col-6:last-child {
		display: flex;
		justify-content: center;
		width: 80%;
	}

	.contslider .info {
		padding: 5% !important;
	}

	.info .col-6 p {
		color: #fff;
		font-size: 22px;
		line-height: 28px;
		margin: 10px 0;
	}

	.contnovedades {
		margin-top: 0;
	}

	.contnovedades .col-12 {
		max-width: 100%;
		display: flex;
		flex-direction: column;
	}

	.contnovedades .col-4 {
		width: 100%;
	}

	.contnovedades {
		padding: 0;
	}

	.contnovedades .novedad {
		padding: 1rem;
	}

	.contnovedades .novedad:nth-child(2) {
		padding: 1rem;
		display: flex;
		align-items: flex-end;
		text-align: right;
	}

	body p {
		font-size: 16px;
	}

	.item h4 {
		font-size: 30px !important;
	}

	.item h4 {
		font-size: 30px !important;
	}

	.pdi .info .col-6:first-child {
		align-items: center;
		justify-content: center;
	}

	.uja-footer__datos-contacto-inner {
		display: block;
		text-align: center;
		padding-bottom: 10px;
	}

	footer .logo {
		margin: 0 0 10px 0 !important;
	}

	footer ul {
		justify-content: center;
	}
}

@media only screen and (max-width: 991px) {

	.desktop-hidden {
		display: flex;
	}

	.button-container {
		width: 100%;
		justify-content: flex-end;
	}

	.modal-cursos__content--top {
		flex-direction: column;
		gap: 1rem;
		align-items: center;

		& .modal-cursos__content {
			padding: 0;
			width: 100%;
		}
	}

	.modal-cursos__container {
		padding-bottom: 1rem !important;
	}

	.modal-cursos__content {
		padding: 0;
		width: 100%;
	}

	.modal-cursos__content--bottom {
		padding: 2rem;

		& .modal-cursos__content {
			padding-top: 0;
		}
	}

	.modal-cursos__content--subtitle,
	.modal-cursos__content--title {
		display: none;
	}

	.textomodal .ponentes-container {
		flex-direction: column;
		gap: 0;
	}

	.textomodal .ponente {
		padding-bottom: 0;
	}

	.estudiantes,
	.pdi,
	.ptgas,
	.microcredenciales,
	.empresas {
		z-index: 0;
		position: relative;
		top: 0;
	}

	.logo img {
		max-width: 210px;
		margin-left: 15%;
	}

	.contselectfechas {
		padding: 15px 45px 25px 45px;
		width: 100%;
		background: #1f6e34;
	}

	.uja-footer__logo-uja {
		text-align: center;
		margin-bottom: 15px;
	}

	.uja-footer__logo-uja img {
		width: 120px;
		margin-top: 15px;
	}

	.cont_footer .col-6 {
		width: 100%;
		margin-bottom: 15px;
	}

	.cont_footer .col-6 {
		width: 100%;
		margin-top: 15px;
	}

	.uja-footer__datos-contacto-inner p {
		color: #fff;
		text-align: center;
		margin-bottom: 15px;
	}

	footer ul {
		display: flex;
		flex-direction: row;
		align-items: center;
		list-style: none;
		padding: 0;
		margin: 15px 0;
		flex-wrap: wrap;
		justify-content: center;
	}

	.navbar-nav {
		background-color: #1f6e34;
	}

	.nav-item {
		margin: 0 !important;
		padding: 15px 45px !important;
	}

	.nav-item a {
		color: #fff !important;
	}

	.navbar-nav li:last-child {
		margin: 0 0 0 0 !important;
	}

	.navbar-nav {
		padding: 15px 0;
	}

	header .navbar {
		padding: 0 0;
	}

	.navbar-brand {
		margin-left: 25px;
	}

	.navbar-toggler {
		margin-right: 25px;
	}

	.cont_footer .col-6:last-child {
		align-items: center !important;
	}
}

@media only screen and (max-width: 768px) {

	footer {
		padding: 50px 5%;
	}

	.icon.uja-footer__logo-uja {
		justify-content: center;
		margin-right: 0;
	}

	.mobile-hidden-768 {
		display: none;
	}

	.modal-cursos__taxonomies--container {
		flex-direction: column;
		gap: 0;
	}
	
	.card-wrapper--vertical {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}

	.category-info h2 {
		width: 100%;
	}

	.category-info .category-description {
		display: none;
	}

	.category-info p {
		width: 100%;
	}

	.card-wrapper__loading,
	.card-wrapper__empty-results {
		padding-top: 2rem;
		gap: 1rem;
		
		& span {
			font-size: 1rem;
		}

		& svg {
			width: 10%;
		}
	}

	.course-browser__header,
	.course-browser__content {
		padding-left: 0;
	}

	.course-browser__header {
		margin-bottom: 2rem;
	}

	.course-browser__searchmenu,
	.course-browser__searchbar {
		width: 100%;

		& input {
			width: 100%;
		}
	}

	.modal-cursos__content {
		padding: 2rem;
	}

	.modal-cursos__content--bottom {
		padding: 0;
	}

	.course-browser__navigation {
		flex-direction: row;
		flex-wrap: wrap;

		& .course-browser__navigation-item {
			margin: 0;
			padding: 0;
		}

		& .course-browser__tooltip__text {
			display: none;
		}
	}
	
	.course-browser {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.textomodal .ponentes-container {
		flex-direction: column;
		gap: 0;
	}

	.textomodal .ponente {
		padding-bottom: 0;
	}

	.cartelesverano {
		grid-template-columns: repeat(1, 1fr);
	}

	.contpanelcookies {
		height: 40%;
	}

	#player {
		width: 290px !important;
		min-height: 163px !important;
	}

	.intsection.puzzle .col-12:first-child .col-6:last-child > div {
		width: 290px !important;
		height: 163px !important;
	}

	.nav-item a button {
		color: #fff !important;
	}

	.logo img {
		max-width: 210px;
		margin-left: 15%;
	}

	.cont_footer .logo {
		margin-left: 0;
	}

	.item .col-12 {
		max-width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.item .col-6:first-child {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		width: 100%;
	}

	.item .col-6:last-child {
		display: flex;
		align-items: flex-end;
		flex-direction: column;
		justify-content: center;
		text-align: right;
		width: 100%;
	}

	.item .col-12 {
		max-width: 100%;
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		width: 100%;
	}

	.item .col-6:last-child {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
		width: 100%;
	}

	.item .col-6:first-child {
		width: 100%;
		padding: 0 25px;
	}

	.fixpdleft {
		padding-left: 0;
	}

	.centermid {
		margin: 15px 0 5px 0 !important;
	}
}

@media only screen and (max-width: 768px) {
	.textomodal .ponentes-container {
		flex-direction: column;
		gap: 0;
	}

	.textomodal .ponente {
		padding-bottom: 0;
	}

	.textomodal .ponente {
		padding-bottom: 0;
	}

	.cookies span {
		font-size: 17px;
		padding: 0 10px;
		width: 100% !important;
		display: block;
		text-align: center;
	}

	.cookies {
		flex-direction: column;
		padding: 15px 0;
	}

	.cookiesacept {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	.cookiesacept .col-6:last-child {
		margin-left: 0;
		margin-top: 0;
		width: 100%;
		flex-direction: column;
	}

	.cookiesacept .col-6:last-child button {
		margin-top: 15px;
		margin: 5px 0 !important;
	}

	.fixmg button {
		right: 2px;
		position: relative;
		top: 0;
		max-width: 203px;
	}

	.cookies button {
		margin: 5px 0 !important;
		padding: 3px;
	}

	.cookiesacept button {
		padding: 3px;
	}

	.contpanelcookies {
		height: 70%;
	}
}
